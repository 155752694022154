import { createRouter, createWebHashHistory } from 'vue-router'
import OAuth from '../views/OAuth.vue'
import { refresh } from '../utils/urlUtils'

const routes = [
  {
    path: '/',
    name: 'OAuth',
    component: OAuth
  },

  {
    path : '/panel',
    name : 'LoginPanel',
    component : () => import('../views/LoginPanel.vue'),
    children : [
      {
        path: '/login',
        name: 'Login',
        component : () => import('../views/Login.vue')
      },
    
      {
        path : '/signup',
        name : 'Signup',
        component : () => import('../views/Signup.vue')
      }
    ]
  },

  {
    path : '/template',
    name : 'Template',
    component : () => import('../views/Template.vue')
  },

  {
    path : '/error',
    name : 'Error',
    component : () => import('../views/Error.vue')
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

// 使用全局的前置守卫来控制跳转
router.beforeEach((to, from, next) => {
  if (from.name === 'Error') {
    
    alert('不能从错误页返回，请点击重新登录')

    next(false)
  } else if (to.name === 'OAuth' && from.name != undefined) {
    refresh()
  } else if (from.name === 'Template') {
    refresh()
  } else {
    next()
  }
})

export default router