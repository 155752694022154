import { resourceName } from './constants'
/*
    extract value with given key from a url
    e.g.
        https://intro.zhenghaolu.com/?id=1

        getQuery("id") returns 1
*/
export function getQuery(name) {
    var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i');
    var r = window.location.search.substr(1).match(reg);
    if (r != null) {
        return unescape(r[2]);
    }
    return null;
}

export function getQueryVariable(name) {
    var query = window.location.search.substring(1);
    var vars = query.split("&");
    for (var i = 0;i < vars.length; i++) {
        var pair = vars[i].split("=");
        if(pair[0] == name) {
            return pair[1];
        }
    }
    return(false);
}


// 跳转
export function redirectTo(url) {
    window.location.href = url
}

// 刷新jscode
export function refresh() {
    let resource = localStorage.getItem('payroll')

    if (resource && resource != null) {
        const newUrl = `https://pas.leadmicro.cn/?${resourceName}=${encodeURIComponent(resource)}`
        redirectTo(newUrl)
    }
}