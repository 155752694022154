// 检查密码的regex
export const symbolRule = /^.*(?=.{10,})(?=.*\d)(?=.*[A-Z])(?=.*[a-z])(?=.*[!@#$%^&*?+\\.\-=_]).*$/
export const cnPattern = /[\u4E00-\u9FA5]/
export const hasBlankPattern = /^.*(?=.{1,})(?=.* ).*$/
export const serialNumberPattern = /^([0-9])\1{4,}$/
// constants
export const resourceName = 'content'
export const templateId = 'tid'
export const jscodeKey = 'code'

const firmInfo = {
    'templateId': 0,
    'templateId_bonus': 2
}

const placeholder_template_id = 0
export function getTemplateIdBy(yearMonth) {
    let templateId = placeholder_template_id
    if (yearMonth.indexOf('年终奖') !== -1) {
        // 年终奖模版
        templateId = firmInfo.templateId_bonus
    } else {
        // 月工资模版
        templateId = firmInfo.templateId
    }
    return templateId
}

// 为模版页准备的字典
export const WuXianYiJin = [
    '养老险',
    '养老金', 
    '养老保险',
    '养老保险金',
    '扣减养老保险',
    '公积金',
    '个人公积金',
    '住房公积金',
    '个人住房公积金',
    '扣减公积金',
    '补充公积金',
    '扣减补充公积金',
    '社保调整', 
    '社保基金', 
    '社保', 
    '社会保险', 
    '社会保障险',
    '社会保障金',
    '社会保险金',
    '个人养老金',
    '扣减社保', 
    '爱心基金', 
    '医疗金', 
    '医疗险',
    '医疗保险', 
    '医疗保险金',
    '个人医疗金',
    '扣减医疗保险',
    '失业金',
    '失业险', 
    '失业保险', 
    '失业保险金',
    '扣减失业保险',
    '个人失业金',
    '个税',
    '扣减个税',
    '工资税',
    '个人所得税',
    '公积金调整',
    '工伤金',
    '工伤险',
    '工伤保险',
    '工伤保险金',
    '因公伤残险',
    '因公伤残保险',
    '因公伤残保险金',
    '生育险',
    '生育保险',
    '生育保险金',
    '其他扣减',
    '备注'
]

// 岗位
export const DeptKeys = [
    '部门',
    '部门名称',
    '所属部门',
    '所在部门'
]

// 级别
export const LevelKeys = [
    '职级',
    '级别',
    '职称',
    '职位'
]

export const YearMonthKeys = [
    '月份',
    '工资月度',
    '工资月份',
    '发放月份',
    '工资发放月份',
    '年月',
    '工资年月',
    '发放时间'
]

export const UserIdKeys = [
    '工号',
    '员工号',
    '员工编号',
    '人员编号',
    '员工ID',
    '员工Id',
    '员工id'
]

export const AccruedKeys = [
    '应得工资',
    '应得合计',
    '应发工资',
    '应发合计',
    '应得收入',
    '应得收入',
    '账面工资',
    '账面收入',
    '应发绩效奖金'
]

export const ActualKeys = [
    '实发工资',
    '实发合计',
    '到手工资',
    '到手合计',
    '实际工资',
    '税后工资',
    '税后合计',
    '实发绩效奖金'
]

export const Monthly_Salary = ['月工资', '月度工资']

export const Month_Salary_Keys = [
    '固定工资',
    '基本工资',
    '浮动工资',
    '浮动工资（绩效考核）',
    '浮动工资（绩效）',
    '浮动工资（绩效奖）',
    '浮动工资（绩效工资）',
    '浮动工资(绩效考核)',
    '浮动工资(绩效)',
    '浮动工资(绩效奖)',
    '浮动工资(绩效工资)',
    '浮动工资 (绩效考核)',
    '浮动工资 (绩效)',
    '浮动工资 (绩效奖)',
    '浮动工资 (绩效工资)',
    '工龄奖',
    '岗位津贴',
    '绩效奖金',
    "满勤奖"
]

export function is_month_salary_key(key) {
    if (Month_Salary_Keys.indexOf(key.trim()) !== -1) {
        return true
    } else {
        for (let idx = 0; idx < Month_Salary_Keys.length; idx++) {
            if (key.trim().indexOf(Month_Salary_Keys[idx]) !== -1) {
                console.log(true)
                return true
            }
        }
        return false
    }
}