<template>
    <div style="flex">正在请求微信...</div>
</template>

<script>
import { getQueryVariable, redirectTo } from '../utils/urlUtils'
import { resourceName, jscodeKey } from '../utils/constants'
import { wxOAuth2ReqUrl } from '../utils/weixinUtils'
export default {
    name : 'OAuth',
    mounted() {
        let code = getQueryVariable(jscodeKey)
        if (code) {
            this.$router.replace(`/panel?${jscodeKey}=${code}`)
        } else {
            // 未发现url中有‘code’，所以尚未获得微信授权
            localStorage.removeItem('payroll')

            let resource = getQueryVariable(resourceName)
            if (resource != null) {
                // payroll为敏感信息，但是经过加密，可以暂存于session中
                localStorage.setItem('payroll', decodeURIComponent(resource))

                let ls = localStorage.getItem('payroll')
                if (ls && ls != null) {
                    // 使用企业微信的OAuth2授权接口
                    const requestUrl = wxOAuth2ReqUrl()
                    redirectTo(requestUrl)
                }
            } else {
                this.$router.replace('/error?reason=' + '推送链接中未找到需要的信息')
            }
        }
    }
}
</script>