<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
export default {
  name: 'App',
  data() {
    return {
      beginTime : 0,
      gap: 0
    }
  },

  mounted() {
    window.addEventListener('beforeunload', e => this.beforeunloadHandler(e))
    window.addEventListener('unload', e => this.unloadHandler(e))
  },

  beforeUnmount() {
    window.removeEventListener('beforeunload', e => this.beforeunloadHandler(e))
    window.removeEventListener('unload', e => this.unloadHandler(e))
  },

  methods: {
    beforeunloadHandler() {
      this.beginTime = new Date().getTime()
    },
    
    unloadHandler() {
      let gap = new Date().getTime() - this.beginTime
      this.gap = gap
      if (gap <= 5) {
        localStorage.removeItem('payroll')
      }
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
